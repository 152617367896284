import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import axios from "../../utils/axios";
import { baseUrl, rupiah } from "../../utils/strings";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import LoadingPage from "../../components/LoadingPage";

// icons
import { BiArrowBack } from "react-icons/bi";
import { Button } from "../../components/Button";

export default function BayarQRIS() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [isLoading, setLoading] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState(null);

  const expiredDate = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString();

  // const dataForm = {
  //    "reference_id": state.invoice,
  //    "type": "DYNAMIC",
  //    "currency": "IDR",
  //    "amount": state.total_jml_transaksi,
  //    "expires_at": expiredDate
  // }

  const generateInvoiceMutation = useMutation(
    async (data) => {
      const dataForm = {
        "external_id": state.invoice,
        "amount": state.total_jml_transaksi,
        "payer_email": localStorage.getItem("email"),
        "description": "Pembayaran untuk transaksi di " + state.toko.nama_toko
      }

      // axios.defaults.headers.common[
      //   "Authorization"
      // ] = `Basic eG5kX2RldmVsb3BtZW50Xzhqbk44VzVwU2Q3SEt6YVpxYzVhbzhuaWkyN25tdHZYbTd0Sk1BbFVXODY4eGhtWVBoUk9hV0NXTlBQV3E6`;
      // axios.defaults.headers.common[
      //   "api-version"
      // ] = `2022-07-31`;
      // const response = await axios.post(`https://api.xendit.co/qr_codes`, dataForm);
      const response = await axios.post(`menu/create-invoice`, dataForm);
      const res = await response.data;

      return res.data;
    },
    {
      onMutate: () => {
        setLoading(!isLoading);
      },
      onSettled: async (data, error) => {
        setLoading(false);
      },
      onSuccess: async (data) => {
        setInvoiceUrl(data.invoice_url)
      },
      onError: async (error) => {
        console.log(error)
      },
    }
  );

  useEffect(() => {
    generateInvoiceMutation.mutate()
  }, [])

  function simpanPromo() {
    // sessionStorage.setItem(
    //   "promo",
    //   JSON.stringify({
    //     id: selectedPromo.id,
    //     judul_promo: selectedPromo.judul_promo,
    //     tanggal_awal_promo: selectedPromo.tanggal_awal_promo,
    //     periode_promo: selectedPromo.periode_promo,
    //     promo: selectedPromo.promo,
    //   })
    // );
    navigate("/pesanan");
  }

  return (
    <>
      <div className="px-4 flex flex-col flex-1 mt-4">
        <Link to="/pesanan">
          <BiArrowBack size="20" className="text-blue-500 mb-4" />
        </Link>
        <div className="mb-6">
          <h3 className="font-bold mb-1">Lakukan Pembayaran</h3>
          <p className="text-xs">
            Kunjungi link di bawah untuk melakukan pembayaran.
          </p>
        </div>
        {isLoading ? (
            <LoadingPage />
          )
          : invoiceUrl ?
            <div className="w-full">
              <Button
                title="Bayar Sekarang"
                type="button"
                className="w-full bg-blue-500 border-0 hover:bg-blue-700 text-xs"
                onClick={() => {
                  window.location = invoiceUrl
                }}
              />
            </div>
            : null
        }
      </div>
    </>
  );
}
