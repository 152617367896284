import React, { useEffect, useState } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../utils/axios";
import { Button } from "../../components/Button";
import { isError, useQuery, useMutation } from "react-query";
import { TiWarningOutline } from "react-icons/ti";
import { BsCheckCircle, BsArrowRight } from "react-icons/bs";
import { MdQrCodeScanner } from "react-icons/md";
import { Link } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage";
import PesananKosong from "../../layouts/PesananKosong";
import { rupiah, baseUrl } from "../../utils/strings";
import { swNormal } from "../../utils/sw";
import moment from "moment";
import Select from "react-select";

export default function StatusOrder() {
  moment.locale("id");
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const no_transaksi = sessionStorage.getItem("no_transaksi");
  const [isLoadingInvoice, setLoadingInvoice] = useState(false);
  const [paymentOption, setPaymentOption] = useState(null);
  const [metodePembayaran, setMetodePembayaran] = useState(null);

  const fetchSetting = async () => {
    let toko = localStorage.getItem("branch");
    const response = await axios.get(`toko/setting-toko?toko=${toko}`);
    const res = response.data.data;

    setMetodePembayaran(res.metode_pembayaran)
  };

  useEffect(() => {
    fetchSetting()

    const status = searchParams.get('status')

    if (status == 'payment_success') {
      swNormal("Berhasil", "Pembayaran Berhasil", "success");
    } else if (status == 'payment_failed') {
      swNormal("Gagal", "Pembayaran Gagal", "error");
    }
  }, [])

  // const paymentOptions = [
  //   { value: "MANDIRI", label: "Mandiri" },
  //   { value: "BNI", label: "BNI" },
  //   { value: "BRI", label: "BRI" },
  //   { value: "BCA", label: "BCA" },
  // ];

  // react query
  const { isLoading, isFetching, isError, data } = useQuery(
    ["data-order"],
    () => fetchData(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchData = async () => {
    let branch = localStorage.getItem("branch");
    let no_transaksi = sessionStorage.getItem("no_transaksi");

    const response = await axios.get(
      `menu/cari-order-transaksi?branch=${branch}&no_transaksi=${no_transaksi}`
    );

    const res = response.data;
    const order = res.data;

    return order;
  };


  const branch = localStorage.getItem("branch");
  const namaToko = branch
    .split("-")
    .map(([first, ...rest]) => first.toUpperCase() + rest.join(""))
    .join(" ");

  const pilihWarnaStatusOrder = (status_bayar, status_order) => {
    if (status_order === "expired")
      return "bg-gray-400";

    switch (status_bayar) {
      case "not_paid":
        return "bg-yellow-400";
      case "already_paid":
        return "bg-green-400";
      default:
        return "";
    }
  };

  function warnaAlert(status_pembayaran) {
    if (
      status_pembayaran === "already_paid"
    ) {
      return "bg-blue-300 text-white";
    } else if (
      status_pembayaran === "not_paid"
    ) {
      return "bg-yellow-300 text-slate-700";
    } 
  }

  function pesanAlert(
    status_pembayaran,
    source,
    status_order
  ) {
    if (status_pembayaran === "not_paid" && status_order !== "expired") {
      return "Silahkan Lakukan Pembayaran ke " + namaToko;
    } else if (
      status_pembayaran === "already_paid"
    ) {
      return "Pesanan sedang diproses. Silakan datang ke lokasi yang telah ditentukan. Terima kasih.";
    } else if (
      status_order === "expired"
    ) {
      return "Pesanan anda telah kadaluarsa";
    } else {
      return "-";
    }
  }

  function iconAlert(status_pembayaran) {
    if (
      status_pembayaran === "already_paid"
    ) {
      return <BsCheckCircle size="30" />;
    } else if (
      status_pembayaran === "not_paid"
    ) {
      return <TiWarningOutline size="30" />;
    } 
  }

  const layoutProduk = (item, index) => (
    <div className="order__products" key={index}>
      <div className="order__item p-3 flex bg-white">
        <img
          src={baseUrl + item.produk.gambar}
          alt={item.produk.gambar}
          className="h-16 w-16 object-cover rounded-lg"
        />
        <div className="ml-4 flex-1">
          <div className="mb-2">
            <h5 className="text-sm font-semibold tracking-wide">
              {item.produk.nama_produk}
            </h5>
            <span className="text-xs text-slate-300">
              {item.produk.kategori_produk.kategori}
            </span>
          </div>
          <div className="mb-1 flex justify-between items-center">
            <span className="inline-block mr-1 text-sm font-semibold text-blue-500">
              IDR {rupiah(item.harga_produk)}
            </span>
            <p className="text-sm text-slate-400">x{item.qty}</p>
          </div>
        </div>
      </div>
    </div>
  );

  const payInvoice = async (data) => {
    if (state?.invoice_url) {
      window.open(state.invoice_url, '_blank');
    } else {
      setLoadingInvoice(true)

      try {
        const items = data.penjualan_detail.map((item, index) => {
          return {
            'name': item.nama_produk,
            'quantity': item.qty,
            'price': item.harga_diskon == 0 ? item.harga_produk : item.harga_diskon,
            'category': item.produk.kategori_produk.kategori,
          }
        });

        const dataForm = {
          "id_user": localStorage.getItem("idPelanggan"),
          "external_id": data.invoice,
          "amount": data.total_jml_transaksi,
          "payer_email": localStorage.getItem("email"),
          "description": "Pembayaran untuk transaksi di " + data.toko.nama_toko,
          "success_redirect_url": process.env.REACT_APP_BATHIKU_MENU_DOMAIN + "pesanan?status=payment_success",
          "failure_redirect_url": process.env.REACT_APP_BATHIKU_MENU_DOMAIN + "pesanan?status=payment_failed",
          "items": items
        }

        const response = await axios.post(`menu/create-invoice`, dataForm);
        const res = response.data.data;

        window.open(res.invoice_url, '_blank');
      } catch (error) {
        console.error("Error generating invoice:", error);
      } finally {
        setLoadingInvoice(false)
      }
    }
  }

  // const createVirtualAccount = useMutation(
  //   async (data) => {
  //     const dataForm = {
  //        "external_id": localStorage.getItem("idPelanggan"),
  //        "is_closed": true,
  //        "expected_amount": data.total_jml_transaksi,
  //        "bank_code": paymentOption,
  //        "name": localStorage.getItem("namaPelanggan")
  //     }

  //     const response = await axios.post(`menu/create-va`, dataForm);
  //     const res = await response.data;

  //     return res.data;
  //   },
  //   {
  //     onMutate: () => {
  //       setLoadingInvoice(!isLoading);
  //     },
  //     onSettled: async (data, error) => {
  //       setLoadingInvoice(false);
  //     },
  //     onSuccess: async (data) => {
  //       navigate('/bayar-virtual-account', { state: data })
  //     },
  //     onError: async (error) => {
  //       console.log(error)
  //     },
  //   }
  // );

  return (
    <>
      <div className="px-4 flex-1 flex flex-col">
        <div className="flex justify-start items-center">
          <HiOutlineChevronLeft
            size="20"
            className="cursor-pointer"
            onClick={() => navigate("/")}
          />
          <h5 className="font-bold text-lg text-center flex-1">Pesanan</h5>
        </div>

        <div className="flex-1 flex flex-col mt-4">
          {isLoading ? (
            <div className="col-span-2 flex flex-1 justify-center items-center flex-col space-y-3">
              <LoadingPage />
            </div>
          ) : isError || no_transaksi === null ? (
            <PesananKosong />
          ) : (
            <div className="order__container w-full space-y-4 mb-32 text-sm">
              <div
                className={`alert ${warnaAlert(
                  data.order[0]?.status_bayar
                )} shadow-lg`}
              >
                <div>
                  {iconAlert(
                    data.order[0]?.status_bayar
                  )}
                  <span>
                    {pesanAlert(
                      data.order[0]?.status_bayar,
                      data.order[0]?.jenis_kedatangan,
                      data.order[0]?.status_order
                    )}
                  </span>
                </div>
              </div>
              {data.order[0]?.status_bayar == 'not_paid' && data.order[0]?.status_order != 'expired' && metodePembayaran != 'offline'
                ? isLoadingInvoice ? (
                    <div className="col-span-2 flex flex-1 justify-center items-center flex-col space-y-3">
                      <LoadingPage />
                    </div>
                  ) : (
                    <div className="w-full px-4">
                      <div className="d-flex flex-column">
                        {/*<Select
                          options={paymentOptions}
                          className="w-full border-blue-500 mb-4 mt-12"
                          classNamePrefix="select"
                          value={paymentOptions.find(
                            (option) => option.value === paymentOption
                          )}
                          onChange={(event) => setPaymentOption(event.value)}
                          placeholder="Pilih Metode Pembayaran"
                        />*/}
                        <Button
                          type="button"
                          // startIcon={<MdQrCodeScanner size="20" />}
                          title="Bayar Sekarang"
                          className="w-full bg-blue-500 border-0 hover:bg-blue-400 mb-2"
                          onClick={() => payInvoice(data.order[0])}
                          // onClick={() => navigate("/bayar-qris", { state: data.order[0] })}
                        />
                      </div>
                      {/*<Button
                        type="button"
                        endIcon={<BsArrowRight size="20" />}
                        title="Metode pembayaran lain"
                        className="w-full bg-white border-blue-500 text-blue-500 border-0 hover:bg-white mb-2"
                        onClick={() => generateInvoiceMutation.mutate(data.order[0])}
                        // onClick={() => navigate("/bayar-qris", { state: data.order[0] })}
                      />*/}
                    </div>
                ) : null}
              <div>
                {data.order.map((item, index) => (
                  <div key={index} className="mb-12">
                    <div>
                      <div className="order__content">
                        <div>
                          <div
                            className={`order__status text-xs py-2 ${pilihWarnaStatusOrder(
                              item.status_bayar, item.status_order
                            )} font-semibold px-4 text-white flex justify-between items-center rounded-t-md`}
                          >
                            <span>{item.invoice}</span>
                            <span>
                              {(item.status_order == "expired" ? item.status_order : item.status_bayar)
                                .replace("_", " ")
                                .toUpperCase()}
                            </span>
                          </div>
                          <div key={index}>
                            {item.penjualan_detail.map((item, index) =>
                              layoutProduk(item, index)
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`border border-slate-200 mt-4 rounded-md`}>
                      <h1 className="text-sm text-black font-semibold mb-2 py-2 px-4 bg-slate-100 rounded-t-md">
                        Informasi Order
                      </h1>
                      <div className="py-2 px-4">
                        <div className="flex justify-between text-xs text-slate-700 mb-2">
                          <h1 className="font-semibold">Nama</h1>
                          <p className="font-semibold">
                            {data != null ? item.nama_pelanggan : ""}
                          </p>
                        </div>
                        <div className="flex justify-between text-xs text-slate-700 mb-2">
                          <h1 className="font-semibold">Tanggal & Waktu</h1>
                          <p className="font-semibold">
                            {moment(data != null ? item.created_at : "").format(
                              "DD-MM-YYYY HH:MM"
                            )}
                          </p>
                        </div>
                        <div className="flex justify-between text-xs text-slate-700 mb-2">
                          <h1 className="font-semibold">Subtotal</h1>
                          <p className="font-semibold">
                            IDR{" "}
                            {rupiah(
                              data != null
                                ? parseInt(item.total_jml_transaksi) + parseInt(item.nominal_disc)
                                : ""
                            )}
                          </p>
                        </div>
                        <div className="flex justify-between text-xs mb-2">
                          <h1 className="font-semibold text-green-500">
                            Diskon
                          </h1>
                          <p className="font-semibold text-green-500">
                            IDR {rupiah(data != null ? item.nominal_disc : "")}
                          </p>
                        </div>
                        {/*<div className="flex justify-between text-xs text-slate-700 mb-2">
                          <h1 className="font-semibold">Pajak</h1>
                          <p className="font-semibold">
                            IDR {data != null ? item.pajak : ""}
                          </p>
                        </div>
                        <div className="flex justify-between text-xs text-slate-700 mb-4">
                          <h1 className="font-semibold">Service Charge</h1>
                          <p className="font-semibold">
                            IDR{" "}
                            {data != null ? item.service_charge : ""}
                          </p>
                        </div>*/}

                        <div className="flex justify-between text-xs text-slate-700 pt-4 border-t border-dashed border-slate-400 mb-2">
                          <h1 className="font-bold">Total Transaksi</h1>
                          <p className="font-bold">
                            IDR{" "}
                            {rupiah(data != null ? item.total_jml_transaksi : "")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/*<div className="w-full px-4 fixed bottom-1 left-0 bg-white flex items-center py-4">
                  <Link
                    to="/home"
                    className="flex-1 flex items-center border-none"
                  >
                    <Button
                      type="button"
                      title="Tambah Lagi"
                      className="w-full bg-blue-500 border-0 hover:bg-blue-400 mb-2"
                    />
                  </Link>
                </div>*/}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
