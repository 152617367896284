import React from 'react';
import { Button, ButtonOutlineSecondary } from './Button'
import { MdClose } from "react-icons/md";

export const Modal = ({ 
  titleModal,
  description,
  confirmAction = null,
  isOpen,
  closeModal,
}) => {
    return (
        <>
          <div className={`fixed z-10 inset-0 flex items-center justify-center p-6 ${isOpen ? '' : 'hidden'}`}>
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="bg-white p-8 rounded shadow-lg z-10 relative">
              <button className="text-gray-500 hover:text-gray-700 absolute top-5 right-5" onClick={() => closeModal()}><MdClose size="24"/></button>
              <div className="flex items-center justify-between mb-2">
                <h2 className="text-xl font-semibold">{titleModal ?? ""}</h2>
              </div>
              
              <p className="text-gray-700">{description ?? ""}</p>

              {confirmAction != undefined ? (
                <div className="flex gap-4 flex-stretch mt-6">
                  <ButtonOutlineSecondary color="blue" className="flex-auto" title="Batal" color="secondary" onClick={() => closeModal()}/>
                  <Button className="flex-auto" title="Konfirmasi" color="secondary" onClick={confirmAction}/>
                </div>
              ) : (
                <div className="flex gap-4 flex-stretch mt-6">
                  <Button color="blue" className="flex-auto" title="OK" color="secondary" onClick={() => closeModal()}/>
                </div>
              )}
            </div>
          </div>
        </>
    );
};
