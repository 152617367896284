import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import CategoryProduct from "../../components/CategoryProduct";
import { SearchProduct } from "../../components/Input";
import LoadingPage from "../../components/LoadingPage";

// libraries
import axios from "../../utils/axios";
import { useQuery } from "react-query";
import ItemProduct from "../../components/ItemProduct";
import { useDispatch, useSelector } from "react-redux";
import { toastDark } from "../../utils/toast";
import {
  cariProduk,
  tambahJumlahBeliProduk,
  tambahProduk,
} from "../../features/produkSlice";

export default function Home() {
  const location = useLocation();

  const { idKategori, kategoriProduk, keyword, produkKeranjang } = useSelector(
    (state) => state.produk
  );

  const noTelepon =
    localStorage.getItem("noTelepon") == "null" ||
    localStorage.getItem("noTelepon") == undefined
      ? "Kosong"
      : localStorage.getItem("noTelepon");
  const namaPelanggan =
    localStorage.getItem("namaPelanggan") == "null" ||
    localStorage.getItem("namaPelanggan") == undefined
      ? "Kosong"
      : localStorage.getItem("namaPelanggan");
  const dispatch = useDispatch();

  // react query
  const { isFetching, data, refetch } = useQuery(
    ["data-produk", kategoriProduk, keyword],
    () => fetchData(),
    {
      staleTime: 120000,
      refetchInterval: 120000,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (location.pathname === '/') {
      refetch();
    }
  }, [location.pathname]);

  const filteredData = data?.filter((item) => item.status_produk == "1");

  const layoutProduk = (item, index) => {
    const cartData = JSON.parse(sessionStorage.getItem('produkKeranjang'));
    const cart = cartData?.filter(cartItem => cartItem.id === item.id);

    return (
      <ItemProduct
        key={index}
        gambar={item.gambar}
        judul={item.nama_produk}
        harga_jual={item.harga_jual}
        stok={item.stok}
        jumlah_beli={cart != undefined && cart.length > 0 ? cart[0].jumlah : 0}
        harga_diskon={item.harga_diskon}
        clickAction={() => tambahProdukKeranjang(item)}
      />
    );
  }

  const fetchData = async () => {
    let branch = localStorage.getItem("branch");
    const response = await axios.get(
      `produk/produk-home?sort=DESC&id_kategori=${idKategori}&s=${keyword}&toko=${branch}`
    );
    const res = response.data;
    const produk = res.data.data;

    return produk;
  };

  const tambahProdukKeranjang = (item) => {
    let dataProdukKeranjang = produkKeranjang || [];

    toastDark(`${item.nama_produk} Sudah Ditambahkan ke Keranjang`, item.id_produk);
    if (dataProdukKeranjang.length > 0) {
      let cariDataProduk = dataProdukKeranjang.find(
        ({ id_produk }) => id_produk === item.id_produk
      );
      if (cariDataProduk) {
        dispatch(tambahJumlahBeliProduk({ id: item.id_produk }));
      } else {
        dispatch(tambahProduk(item));
      }
    } else {
      dispatch(tambahProduk(item));
    }
  };

  const handleCariProduk = (e) => {
    dispatch(cariProduk({ keyword: e.target.value }));
  };

  return (
    <>
      <div className="px-4 flex flex-col flex-1">
        <SearchProduct
          placeholder="Cari produk..."
          onChange={handleCariProduk}
        />
        {/* <Promo /> */}
        <CategoryProduct />
        {isFetching ? (
          <div className="col-span-2 flex flex-1 justify-center items-center flex-col space-y-3">
            <LoadingPage />
          </div>
        ) : (
          <div className="container-produk grid grid-cols-2 gap-4 mt-2">
            {filteredData.map((item, index) => layoutProduk(item, index))}
          </div>
        )}
      </div>
    </>
  );
}
