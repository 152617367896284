import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import axios from "../../utils/axios";
import { baseUrl, rupiah } from "../../utils/strings";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import LoadingPage from "../../components/LoadingPage";

// icons
import { BiArrowBack, BiCheck } from "react-icons/bi";
import { Button } from "../../components/Button";

export default function BayarQRIS() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [isLoading, setLoading] = useState(false);
  const [accountNumber, setAccountNumber] = useState(state.account_number);
  const [bank, setBank] = useState(state.bank_code);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {

  }, [])

  return (
    <>
      <div className="px-4 flex flex-col flex-1 mt-4">
        <Link to="/pesanan">
          <BiArrowBack size="20" className="text-blue-500 mb-4" />
        </Link>
        <div className="mb-6">
          <h3 className="font-bold mb-1">Lakukan Pembayaran</h3>
          <p className="text-xs">
            Masukkan nomor Virtual Account di bawah untuk melakukan pembayaran.
          </p>
        </div>
        {isLoading ? (
            <LoadingPage />
          )
          : accountNumber ?
            <>
              <div className="w-full mb-8">
                <span className="block">No. Virtual Account</span>
                {isCopied}
                <div className="flex items-center justify-start">
                  <span className="text-primary text-[24px]">{accountNumber}</span>
                  <Button
                    title={isCopied ? "Disalin" : "Salin"}
                    type="button"
                    startIcon={isCopied ? <BiCheck size="28" /> : null}
                    className={isCopied ? `border-green-500 text-green-500 border-0 bg-green-500 hover:bg-green-300 mx-4 pr-4` : `border-blue-500 text-blue-500 border-1 hover:bg-blue-700 mx-4 bg-white`}
                    onClick={() => {
                      navigator.clipboard.writeText(accountNumber);
                      setIsCopied(true)
                    }}
                  />
                </div>
              </div>
              <div className="w-full">
                <Button
                  title="Selesai"
                  type="button"
                  className="w-full bg-blue-500 border-0 hover:bg-blue-700 text-xs"
                  onClick={() => navigate('/pesanan')}
                />
              </div>
            </>
            : null
        }
      </div>
    </>
  );
}
